code {
  display: inline-block;
  padding: 0.25rem 0.5rem;
}
.pin-field-container {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: center;
  margin: 2rem 0;
}
.pin-field {
  border: 1px solid #d3d3d3;
  border-right: none;
  font-size: 2rem;
  height: 4rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: color, border, box-shadow, transform;
  width: 4rem;
}
.pin-field:first-of-type {
  border-radius: 0.5rem 0 0 0.5rem;
}
.pin-field:last-of-type {
  border-radius: 0 0.5rem 0.5rem 0;
  border-right: 1px solid #d3d3d3;
}
.pin-field:focus {
  border-color: #686de0;
  box-shadow: 0 0 0.25rem rgba(104, 109, 224, 0.5);
  outline: none;
}
.pin-field:focus + .pin-field {
  border-left-color: #686de0;
}
.pin-field:invalid {
  animation: shake 3 linear 75ms;
  border-color: #dc3545;
  box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.pin-field:invalid + .pin-field {
  border-left-color: #dc3545;
}
.pin-field.complete {
  border-color: #6ab04c;
  color: #6ab04c;
}
.pin-field.complete[disabled] {
  background: rgba(106, 176, 76, 0.1);
  cursor: not-allowed;
  opacity: 0.5;
}
.pin-field.complete + .pin-field {
  border-left-color: #6ab04c;
}
@keyframes shake {
  from {
    transform: translateY(-5%);
 }
  to {
    transform: translateY(5%);
 }
}